import React, { useEffect } from 'react';
import { Route, RouteProps, useLocation } from 'react-router-dom';
import { setShowSideNav } from 'src/components/routes/root/MainLayout/mainLayoutStore';
import { redirectAfterLoginStorageKey } from 'src/constants/localStorage';
import * as routes from 'src/constants/routes';
import { useAuthStore } from 'src/store/authStore';
import { UserPermission } from 'src/typings/User';
import { isUserFromAirtameAddUrl } from 'src/utils/isUserFromAirtameAddUrl';
import { Redirect } from './Redirect';

export type AuthRouteProps = {
  showNav?: boolean;
  userPermissions?: UserPermission[];
  component: React.ComponentType<unknown>;
} & RouteProps;

/**
 * Auth route can only be accessed if the user is authenticated.
 */
export const AuthRoute = ({
  showNav,
  userPermissions,
  component: Component,
  ...rest
}: AuthRouteProps): React.ReactElement => {
  const redirectPath = useGetRedirectPath(userPermissions);

  useEffect(() => {
    setShowSideNav(!!showNav);
  }, [showNav]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

function useGetRedirectPath(userPermissions?: UserPermission[]): string | undefined {
  const userIsAuthenticated = useAuthStore(state => state.isAuthenticated);
  const location = useLocation();
  const path = location.pathname;
  const redirectAfterLogin = window.localStorage.getItem(redirectAfterLoginStorageKey) ?? '';

  if (!userIsAuthenticated) {
    // Note that this localStorage key is cleared on logout
    // by the 'useClearAllOnLogout' hook
    window.localStorage.setItem(redirectAfterLoginStorageKey, path);

    if (isUserFromAirtameAddUrl()) {
      // Users that come from 'airtameAddUrl' are redirected to:
      //   - /devices/setup if logged in
      //   - /signup/welcome if logged out
      return routes.signup;
    }

    return routes.login;
  }

  if (redirectAfterLogin) {
    window.localStorage.removeItem(redirectAfterLoginStorageKey);

    return redirectAfterLogin;
  }

  if (
    userPermissions?.every(permission => userPermissions?.includes(permission)) === false ||
    // SSO redirect routes
    [routes.oAuthMicrosoft, routes.oAuthGoogle].includes(path)
  ) {
    return routes.devices;
  }

  return;
}
