import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useQueryClient } from '@tanstack/react-query';
import { googleIdentityStoreName } from 'src/components/routes/shared/GoogleAuth/googleIdentityStore';
import { usePrevious } from 'src/hooks/usePrevious';
import { useAuthStore } from 'src/store/authStore';
import { clearAllCookies } from 'src/utils/cookies';
import { resetAllZustandStores } from 'src/utils/zustand/createZustandStore';

/**
 * Clear everything on logout:
 * - Cookies
 * - Local storage
 * - React queries
 * - Redux store
 * - Zustand store
 */
export function useClearAllOnLogout(): void {
  const { instance } = useMsal();
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const wasAuthenticated = usePrevious(isAuthenticated);
  const logOut = wasAuthenticated && !isAuthenticated;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (logOut) {
      clearAllCookies();
      localStorage.clear();
      queryClient.clear();
      resetAllZustandStores(
        // Google store must not be reset because we are appending the scripts
        // only once in the lifetime of the app
        [googleIdentityStoreName]
      );

      // Remove possible MS cookies leftovers
      instance.logout({
        onRedirectNavigate: () => {
          // Return false to stop navigation after local logout
          return false;
        },
      });
    }
  }, [logOut]);
}
